import React, { useState } from 'react';
import './EmailPage.css'; // Import the CSS file
import logo from './Icons/logo.png'

function EmailPage() {
    const [email, setEmail] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    async function handleSubmit(event){
        event.preventDefault();
        console.log('Submitted email:', email);
        alert("Thank you!")
        const formData = {'email':email}
        try {
            const response = await fetch('https://lilbrobackend-4e1f701d20d5.herokuapp.com/post', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(formData)
            });
      
            if (!response.ok) {
              throw new Error('Failed to post data');
            }
      
            console.log('Data posted successfully');
            // clear the form fields after successful submission
            setEmail("")
        } 
        catch (error) {
            console.error('Error posting data:', error);
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
            <img src={logo} alt='logo' className='logo'></img>
                <div>
                    Enter your email to Join the fam!
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />
                </div>
                <div>No yahoo though, grow up</div>
                <br />
                <button type="submit" className='backButton'>Join LB!</button>
            </form>
        </div>
    );
}

export default EmailPage;

