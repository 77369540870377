import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ComicsPage.css';

function ComicsPage() {
  const [comicsData, setComicsData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedComicIndex, setSelectedComicIndex] = useState(null);

  useEffect(() => {
    const fetchComicsData = async () => {
      try {
        const response = await fetch('https://lilbrobackend-4e1f701d20d5.herokuapp.com/getComics');

        if (!response.ok) {
          throw new Error('Failed to get data');
        }

        const data = await response.json();
        setComicsData(data.reverse());
      } catch (error) {
        setError(error.message);
      }
    };

    fetchComicsData();
  }, []);

  const handleComicClick = (index) => {
    setSelectedComicIndex(index);
  };

  // Render loading state while fetching data
  if (!comicsData && !error) {
    return <div>Loading...</div>;
  }

  // Render error message if fetching data failed
  if (error) {
    return <div>Error: {error}</div>;
  }

  // Render Comics data
  return (
    <div className="centerComics">
      {selectedComicIndex !== null ? (
        <div>
          <h1>{JSON.parse(comicsData[selectedComicIndex]).title}</h1>
          <div className='comicsframe'>
            <img
              src={JSON.parse(comicsData[selectedComicIndex]).image}
              alt={JSON.parse(comicsData[selectedComicIndex]).title}
            />
          </div>
          <div className="description">
            {JSON.parse(comicsData[selectedComicIndex])
              .description
              .split('\n')
              .map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
          </div>
          {JSON.parse(comicsData[selectedComicIndex]).link !== '' && (
            <a href={JSON.parse(comicsData[selectedComicIndex]).link} alt='More Information' className='backButton'>
              Click here for more!
            </a>
          )}
          <div/>
          <button onClick={() => setSelectedComicIndex(null)} className='backButton'>Back</button>
        </div>
      ) : (
        <div>
          {comicsData.map((comic, index) => {
            const parsedComic = JSON.parse(comic);
            return (
              <button className='comicsButton' key={index} onClick={() => handleComicClick(index)}>
                {parsedComic.title}
              </button>
            );
          })}
          <Link to='/' className='backButton'>Back</Link>
        </div>
      )}
    </div>
  );  
}

export default ComicsPage;

