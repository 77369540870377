import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./GamesPage.css";

function GamesPage() {
  const [gamesData, setGamesData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedGameIndex, setSelectedGameIndex] = useState(null);

  useEffect(() => {
    const fetchGamesData = async () => {
      try {
        const response = await fetch('https://lilbrobackend-4e1f701d20d5.herokuapp.com/getGames');

        if (!response.ok) {
          throw new Error('Failed to get data');
        }

        const data = await response.json();
        setGamesData(data.reverse());
      } catch (error) {
        setError(error.message);
      }
    };

    fetchGamesData();
  }, []);

  const handleGameClick = (index) => {
    setSelectedGameIndex(index);
  };

  // Render loading state while fetching data
  if (!gamesData && !error) {
    return <div>Loading...</div>;
  }

  // Render error message if fetching data failed
  if (error) {
    return <div>Error: {error}</div>;
  }

  // Render Games data
  return (
    <div className="centerGames">
      {selectedGameIndex !== null ? (
        <div>
          <h1>{JSON.parse(gamesData[selectedGameIndex]).title}</h1>
          <div className='gamesframe'>
            <img
              src={JSON.parse(gamesData[selectedGameIndex]).image}
              alt={JSON.parse(gamesData[selectedGameIndex]).title}
            />
          </div>
          <div className="description">
            {JSON.parse(gamesData[selectedGameIndex])
              .description
              .split('\n')
              .map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
          </div>
          {JSON.parse(gamesData[selectedGameIndex]).link !== '' && (
            <a href={JSON.parse(gamesData[selectedGameIndex]).link} alt='More Information' className='backButton'>
              Click here for more!
            </a>
          )}
          <div/>
          <button onClick={() => setSelectedGameIndex(null)} className='backButton'>Back</button>
        </div>
      ) : (
        <div>
          {gamesData.map((game, index) => {
            const parsedGame = JSON.parse(game);
            return (
              <button className='gamesButton' key={index} onClick={() => handleGameClick(index)}>
                {parsedGame.title}
              </button>
            );
          })}
          <Link to='/' className='backButton'>Back</Link>
        </div>
      )}
    </div>
  );
  
}

export default GamesPage;

