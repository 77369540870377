import './AboutMe.css';
import cate from './Icons/cate.jpeg';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function AboutMe() {
  const [whatVisible, setWhatVisible] = useState(false);
  const [whyVisible, setWhyVisible] = useState(false);
  const [howVisible, setHowVisible] = useState(false);

  return (
    <div className="about-me-container">
      <h2 className='backButton'>
        Who?
      </h2>
        <div className='content'>
          <div className='catecontainer'>
            <img src={cate} alt='cate' className='catelogo' />
          </div>
          <p>My name is Gabe Terrazas! A computer science graduate with a passion for other things too!</p>
        </div>

      <h2 className='backButton' onClick={() => setWhatVisible(!whatVisible)}>
        What?
      </h2>
      {whatVisible && (
        <div className='content'>
          <p>The purpose of the blog is to talk about anything related to the topics on the homepage.
            It might be a hot take or just something random, maybe even something interesting!
            I hope to update as often as possible and if you add your email, you can get a weekly recap of my posts!</p>
        </div>
      )}

      <h2 className='backButton' onClick={() => setWhyVisible(!whyVisible)}>
        Why?
      </h2>
      {whyVisible && (
        <div className='content'>
          <p>I started this blog to practice my computer science skills in a way that didn't feel like homework.
            I think making a project you like and care about is the best way to learn effectively, and I can honestly say I learned so many new things working on this.</p>
        </div>
      )}

      <h2 className='backButton' onClick={() => setHowVisible(!howVisible)}>
        How?
      </h2>
      {howVisible && (
        <div className='content'>
          <p>I made the entire website from scratch, using React.js for the frontend and Flask for the backend, which I had some experience with in the past.
            I had to learn how to use MongoDB, Netlify, Emailjs, and Heroku which was a bit tedious but absolutely worth it.
            The main thing I learned from this experience is that in order to be a better developer, you have to get used to reading documentation.
            Opening a bunch of links and finding the solution to your problem is the only way you're gonna get better at coding,
            it can seem boring to read a bunch of docs and articles, especially if you're in a good rhythm coding, but that comes with time, don't rush the process!</p>
        </div>
      )}

      <h2 className='centerDiv'>Check out some of the other stuff I've worked on!</h2>
      <Link to="/pastprojects" className='backButton'>Past Projects</Link>
    </div>
  );
}

export default AboutMe;
