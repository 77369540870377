import React, { useState } from 'react';
import './PostItem.css'; // Import the CSS file

const PostItem = () => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null); // Changed to null for file handling
  const [backImage, setBackImage] = useState(null); // Changed to null for file handling
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [status, setStatus] = useState('true');

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0]; // Get the file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set the image to base64 string
      };
      reader.readAsDataURL(file); // Convert to base64
    }
  };

  const handleBackImageChange = (event) => {
    const file = event.target.files[0]; // Get the file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackImage(reader.result); // Set the image to base64 string
      };
      reader.readAsDataURL(file); // Convert to base64
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  // submit form 
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      title: title,
      image: image, // This will be a base64 string
      backImage: backImage,
      description: description,
      price: price,
      category: 'shop',
      status : status
    };

    try {
      const response = await fetch('https://lilbrobackend-4e1f701d20d5.herokuapp.com/post', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to post data');
      }

      console.log('Data posted successfully');
      // Optionally, clear the form fields after successful submission
      setTitle('');
      setImage(null); // Reset image to null
      setBackImage(null); // Reset image to null
      setDescription('');
      setPrice('');
      setStatus('');
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  return (
    <div className="container"> 
      <div className="form-container-post"> 
        <h1>Are you sure you wanna sell this...</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Title:
              <input type="text" value={title} onChange={handleTitleChange} required />
            </label>
          </div>
          <div>
            <label>
              Image:
              <input type="file" accept="image/*" onChange={handleImageChange} required />
            </label>
          </div>
          <div>
            <label>
              Back Image:
              <input type="file" accept="image/*" onChange={handleBackImageChange}/>
            </label>
          </div>
          <div>
            <label>
              Description:
              <textarea value={description} onChange={handleDescriptionChange} required />
            </label>
          </div>
          <div>
            <label>
              Price:
              <input type="text" value={price} onChange={handlePriceChange} required />
            </label>
          </div>
          <div>
            <label>
              Status:
              <select value={status} onChange={handleStatusChange} required>
                <option value="" disabled>Select status</option>
                <option value="true">For sale</option>
                <option value="false">Not For Sale</option>
              </select>
            </label>
          </div>
          <div>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );  
};

export default PostItem;
