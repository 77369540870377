import "./About.css";
import rene from "./pics/drowning.png";
import sunflower from "./pics/sunflower.png";
import lilb from "./pics/lilb.png";
import pikmin from "./pics/pikmin.webp";
import { Link } from "react-router-dom";

function AboutVideos() {
    return (
        <div className="contentContainer">
            <h1 className="header">Video Projects</h1>

            {/* Drowning Short Film */}
            <div className="section">
                <img src={rene} alt="Drowning thumbnail" className="smaller-img" />
                <a href="https://youtu.be/gVXgMGX5PkY?si=2W0clurRDfa7NoOs" className="styledLink">Drowning Short Film</a>
                <p>
                    My first short film, made when I was 18.  
                    Watching it now, all I see is a high schooler trying to be edgy, haha.  
                    I still love it though, it was such a fun experience.  
                    The soundtrack is crazy good too.
                </p>
            </div>

            {/* Sunflower Claymation */}
            <div className="section">
                <img src={sunflower} alt="Sunflower Thumbnail" className="smaller-img" />
                <a href="https://youtu.be/2BYjwJCNtsg?si=fGNWKAyzNbSl_j1b" className="styledLink">Sunflower Claymation</a>
                <p>
                    I had a little phase where I was obsessed with stop-motion animation—it was one of those random COVID hobbies, you know?  
                    I remember buying a bunch of clay and wire at Hobby Lobby and getting home, realizing I had no idea how to do any of this.  
                    The script was actually written after we (shoutout Valentine) made the characters; we just went with what we thought looked cool.  
                    I think it came out pretty solid. This one was actually cheaper to make than the Drowning short film, haha.
                </p>
            </div>

            {/* My Friend (Feat. Lil B) */}
            <div className="section">
                <img src={lilb} alt="Lil B Thumbnail" className="smaller-img" />
                <a href="https://youtu.be/P_ybEr41Owc?si=R4h-UjLfPzTBFMiv" className="styledLink">My Friend (Feat. Lil B)</a>
                <p>
                    Yeah, that's a real feature from Lil B, the Based God.  
                    I had just signed to my friend's label and saw a tweet that said if you ask Lil B nicely, he'll work with any artist on any budget.  
                    The label paid for the feature, got me the beat, and put the song on Spotify and Apple Music.  
                    All I had to worry about was messaging Lil B (harder than it sounds, LOL)  
                    and working on a music video for the song. I think it came out pretty dope—you should watch it.
                </p>
            </div>

            {/* 30 Minutes of Smash Clips */}
            <div className="section">
                <img src={pikmin} alt="Smash thumbnail" className="smaller-img" />
                <a href="https://youtu.be/0eFNE_lpSuk?si=H2sU2BFEtdgcSGwq" className="styledLink">30 Minutes of Smash Clips</a>
                <p>
                    About 2 years worth of clips I gathered while getting every character in the game in Elite Smash.  
                    I wanted this to be the kind of video you play in the background while you study, eat, or something.  
                    Crazy good music too. I love this game so much.
                </p>
            </div>

            {/* Back Button */}
            <Link to="/pastprojects" className="styledLink">Back</Link>
        </div>
    );
}

export default AboutVideos;
