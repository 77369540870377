import { useState } from "react";
import emailjs from '@emailjs/browser'
import emailLogo from "./Icons/email.png"

function ContactPage(){
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('');

    async function handleSubmit(event) {
        event.preventDefault();
        const serviceId = 'service_f05p6hc';
        const templateId = 'template_h187bsl';
        const publicKey = 'QgQslgOwEI6eYPb-K';
    
        try {
            const templateParams = {
                from_name: name,
                from_email: email,
                to_name: 'littlebrotherblog1@gmail.com',
                message: message
            };

            // Send email
            const response = await emailjs.send(serviceId, templateId, templateParams, publicKey);
            console.log('Email sent to:' + templateParams.to_name, response);
        } catch (error) {
            console.error('Error sending email:', error);
        }
    
        // Clear message after sending all emails
        alert("Sent!")
        setMessage('');
        setName('');
        setEmail('');
    }
    
    return (
        <div className="form-container2">
            <form className="form2" onSubmit={handleSubmit}>
                <h1  >Message me!</h1>
                <div>Suggestions, comments, or just to chat!</div>
                <img src={emailLogo} alt='Email logo' className='logo2'/>
                <input
                    name="name"
                    placeholder="Your name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    required
                />
                <input
                    name="email"
                    placeholder="Your email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                />
                <textarea
                    cols="30"
                    rows="10"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    required
                />
                <button type="submit" className='backButton'>Send email</button>
            </form>
        </div>
    );
}

export default ContactPage