import "./About.css";
import monsterjam from "./pics/monsterjam.png";
import outhere from "./pics/outhere.png";
import getclose from "./pics/getclose.png";
import { Link } from "react-router-dom";

function AboutMusic() {
    return (
        <div className="contentContainer">
            <h1 className="header">Old music, lol</h1>

            <p>
                I was 15 when I made my first song on SoundCloud. I honestly still think it's pretty good (shoutout Cris Bolts).
                I even performed and put together events at places like a tattoo shop, Wet and Wild Water Park, and a bookstore in downtown El Paso with a venue setup in the basement.
            </p>
            
            <p className="highlightText">Here's some of the stuff that survived the "make song private" button.</p>

            {/* Out Here in Shambles */}
            <div className="section">
                <img src={outhere} alt="Out here in shambles" className="smaller-img" />
                <a href="https://soundcloud.com/reptileperson/outhereinshambles?si=d544a042046e495ca408254b1ed569d5&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" className="styledLink">
                    Out Here (In Shambles)
                </a>
                <p>
                    Made this after listening to Lost in the World by Kanye West.
                    I had a couple years of experience making beats at this point. 
                    I tried to capture the slow rise in intensity, then super intense, then sudden cut to the outro flow that LITW has.
                    I slowly added a ton of sounds and cut them all off for the outro.  
                    I'll let you decide if I did a good job or not.
                </p>
            </div>

            {/* Get Close */}
            <div className="section">
                <img src={getclose} alt="Get Close" className="smaller-img" />
                <a href="https://soundcloud.com/reptileperson/sets/get-close?si=6a1c65e47248474ebd9177089bac32db&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" className="styledLink">
                    Get Close
                </a>
                <p>
                    I think it's so funny hearing myself in this song, but it was my first song to hit 8K plays on SoundCloud!  
                    I actually found the beat before writing the song, but I knew I wasn't gonna be able to do the hook correctly.  
                    Tatiana definitely steals the show, she's great in this.  
                    I remember making the song private and getting a DM from a fan from Seattle asking me to make it available again.  
                    Pretty cool, idk. I'm glad that person liked it.
                </p>
            </div>

            {/* Monster Jam */}
            <div className="section">
                <img src={monsterjam} alt="Monster Jam" className="smaller-img" />
                <a href="https://soundcloud.com/reptileperson/monster-jam?si=2f157bc29ee243a98c01d2a39ac10000&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" className="styledLink">
                    Monster Jam
                </a>
                <p>
                    First song in like 5 years. I stopped doing the music thing while I worked on my other projects.  
                    I made this recently, I think I did it to see if it was still fun to rap.  
                    It's a silly song with heavy Action Bronson influences in the lyrics and Westside Gunn vibes in the beat.  
                    I think I killed it, idk.
                </p>
            </div>

            {/* Back Button */}
            <Link to='/pastprojects' className="styledLink">Back</Link>
        </div>
    );
}

export default AboutMusic;
