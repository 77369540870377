import React from 'react';
import { Link } from 'react-router-dom';
import './CategoriesPage.css';

const CategoriesPage = () => {
  return (
    <div className="container-categories">
      <div>
        <div/>
        <Link to={'/admin'} className='link-logo'>Admin</Link>
        <h1 className='indented-h1'>Little Brother</h1>
        <div className="indented-div">By Gabriel Terrazas</div>
        <div className="calendar">
          <div>
            <Link to={'/Music'} className='link-music'>Music</Link>
            <div>Music</div>
          </div>
          <div>
            <Link to={'/Sports'} className='link-sports'>Sports</Link>
            <div>Sports</div>
          </div>
          <div>
            <Link to={'/Games'} className='link-games'>Games</Link>
            <div>Games</div>
          </div>
          <div>
            <Link to={'/Comics'} className='link-comics'>Comics</Link>
            <div>Comics</div>
          </div>
          {/* <div>
            <Link to={'/Events'} className='link-events'>Events</Link>
            <div>Misc</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CategoriesPage;



